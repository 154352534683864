import React, { Fragment, Suspense, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Preloader from "../elements/Preloader";
import { items } from "../scripts/products";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const ProductInner = React.lazy(() =>
  import("../components/ProductInner")
);
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));

const Product = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [image, setImage] = useState({
    src: item?.src[0],
    pos: 0
  });

  useEffect(() => {
    setItem(null);
    console.log(id);
    setOpen(false);
    setItem(items?.filter(x => Number(x?.id) === Number(id))[0]);
    console.log(items?.filter(x => Number(x?.id) === Number(id))[0]);
  }, [id]);

  useEffect(() => {
    setImage({
      src: item?.src[0],
      pos: 0
    });
  }, [item]);

  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* SupportBarOne */}
          <SupportBarOne />

          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar Two */}
          <NavbarTwo />

          {/* Breadcrumb */}
          <Breadcrumb item={item} />

          {/* Service Details Inner */}
          <ProductInner id={id} item={item} isOpen={isOpen} setOpen={setOpen} image={image} setImage={setImage} />

          {/* Footer Two */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default Product;
