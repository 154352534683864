import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const BannerTwo = React.lazy(() => import("../components/BannerTwo"));
const BlogTwo = React.lazy(() => import("../components/BlogTwo"));
const QuoteForm = React.lazy(() => import("../components/QuoteForm"));
const CounterTwo = React.lazy(() => import("../components/CounterTwo"));
const TrendingNow = React.lazy(() => import("../components/TrendingNow"));
const FaqOne = React.lazy(() => import("../components/FaqOne"));
const StickyButton = React.lazy(() => import("../components/StickyButton"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const PartnerTwo = React.lazy(() => import("../components/PartnerTwo"));
const PricingOne = React.lazy(() => import("../components/PricingOne"));
const RequestQuoteOne = React.lazy(() =>
  import("../components/RequestQuoteOne")
);
const ServiceTwo = React.lazy(() => import("../components/ServiceTwo"));
const TestimonialTwo = React.lazy(() => import("../components/TestimonialTwo"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const WhyChooseUsTwo = React.lazy(() => import("../components/WhyChooseUsTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));
const HomeTwo = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* SupportBarOne */}
          <SupportBarOne />

          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar Two */}
          <NavbarTwo />

          {/* Banner Two */}
          <BannerTwo />

          {/* Partner Two */}
          <PartnerTwo />

          {/* About Two */}
          {/* <AboutTwo /> */}

          {/* Counter Two */}
          <CounterTwo />

          {/* Trending Now */}
          <TrendingNow />

          {/* Service Two */}
          <ServiceTwo />

          {/* Why Choose Us Two */}
          <WhyChooseUsTwo />

          <div className="container">
            <div className="pd-top-90 pd-bottom-90">
              <div className="row g-0 justify-content-center">
                <div className='col-lg-12'>
                  <div className='section-title text-center' style={{ marginBottom: 0 }}>
                    <h2 className='title'>REQUEST A QUOTE</h2>
                  </div>
                </div>
                <div className='col-lg-12 align-self-center'>
                  <QuoteForm />
                </div>
              </div>
            </div>
          </div>

          {/* Request Quote One */}
          {/* <RequestQuoteOne /> */}

          {/* Pricing One */}
          {/* <PricingOne /> */}

          {/* Testimonial Two */}
          {/* <TestimonialTwo /> */}

          {/* Video Area One */}
          {/* <VideoAreaOne /> */}

          {/* Faq One */}
          <div className='faq-area'>
            <FaqOne />
          </div>

          {/* <div style={{ width: 100, height: 100, borderRadius: '50%', background: 'blue', backgroundAttachment: 'scroll' }}></div> */}

          {/* Blog Two */}
          {/* <BlogTwo /> */}

          {/* Footer Two */}
          <FooterTwo />

          {/* Footer Bottom One */}
          {/* <FooterBottomOne /> */}

          <StickyButton 
            // link={PATH_DASHBOARD.tasks.new}
            title="Whatsapp"
          />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeTwo;
