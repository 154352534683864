import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import HomeOne from "./pages/HomeOne";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import ScrollToTop from "react-scroll-to-top";
import HomeTwo from "./pages/HomeTwo";
import About from "./pages/About";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Product from "./pages/Product";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Pricing from "./pages/Pricing";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import BoxStyles from "./pages/BoxStyles";
import Terms from "./pages/Terms";
import ReturnPolicy from "./pages/ReturnPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
// import RouteScrollToTop from "./elements/RouteScrollToTop";
// import HomeThree from "./pages/HomeThree";
// import HomeFour from "./pages/HomeFour";
// import HomeFive from "./pages/HomeFive";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

function App({ id }) {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  const options = {
    // passing the client secret obtained from the server
    // clientSecret: '{{CLIENT_SECRET}}',
    clientSecret: "seti_1Mm8s8LkdIwHu7ix0OXBfTRG_secret_NXDICkPqPeiBTAFqWmkbff09lRmSVXe",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <BrowserRouter>
        {/* <RouteScrollToTop /> */}
        <Routes>
          <Route exact path='/' element={<HomeTwo />} />
          {/* <Route exact path='/home-2' element={<HomeTwo />} />
          <Route exact path='/home-3' element={<HomeThree />} />
          <Route exact path='/home-4' element={<HomeFour />} />
          <Route exact path='/home-5' element={<HomeFive />} /> */}
          <Route exact path='/box-styles' element={<BoxStyles />} />
          <Route exact path='/box-styles/by-industry' element={<BoxStyles />} />
          <Route exact path='/box-styles/:id' element={<Product />} />
          <Route exact path='/returns-policy' element={<ReturnPolicy />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-and-conditions' element={<Terms />} />
          {/* <Route exact path='/about' element={<About />} /> */}
          <Route exact path='/service' element={<Service />} />
          <Route exact path='/service-details' element={<ServiceDetails />} />
          <Route exact path='/blog' element={<Blog />} />
          <Route exact path='/blog-details' element={<BlogDetails />} />
          <Route exact path='/pricing' element={<Pricing />} />
          <Route exact path='/faq' element={<Faq />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/about' element={<AboutUs />} />
        </Routes>
        {/* <ScrollToTop smooth color='#FA4318' /> */}
      </BrowserRouter>
    </Elements>
  );
}

export default App;
