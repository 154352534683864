// assets/img/addons/services

export const items = [
  {
    id: 1,
    name: "Mailer Boxes",
    desc: "In today's e-commerce world, mailer boxes are essential for securely sending items to customers. They have a long history, in fact, the first corrugated box was created in the late 19th century. At Go Bespoke Packaging, we specialize in producing custom mailer boxes that not only protect your items but also represent your brand. You can add your brand's name and details about the product inside which will make unboxing experience memorable. Cherry on top, you can have custom inserts for these boxes to ensure your products are well-organized and protected. Explore our affordable options and free shipping to find the perfect packaging for your e-commerce shipping needs.",
    src: [
      "../assets/img/box-styles/mailer-boxes/mailer-boxes-1.jpg",
      "../assets/img/box-styles/mailer-boxes/mailer-boxes-2.jpg",
      "../assets/img/box-styles/mailer-boxes/mailer-boxes-3.jpg",
      "../assets/img/box-styles/mailer-boxes/mailer-boxes-4.jpg",
      "../assets/img/box-styles/mailer-boxes/mailer-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 2,
    name: "Display Boxes",
    desc: "Display boxes are a versatile choice for showcasing and promoting products in supermarkets and retail stores. They come in various shapes and are suitable for a variety of items like candies, cosmetics, electronics and collectibles. You can tailor your display boxes to your specific needs by selecting from different materials, designs and finishes. This customization allows you to create packaging that effectively highlights your products and enhances the in-store presentation for your customers.",
    src: [
      "../assets/img/box-styles/display-boxes/display-boxes-1.jpg",
      "../assets/img/box-styles/display-boxes/display-boxes-2.jpg",
      "../assets/img/box-styles/display-boxes/display-boxes-3.jpg",
      "../assets/img/box-styles/display-boxes/display-boxes-4.jpg",
      "../assets/img/box-styles/display-boxes/display-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 3,
    name: "Perfume Boxes",
    desc: "Perfumes have been loved for ages, not just for their nice scents but for how they reflect our style and feelings. Perfume-making began a long time ago in places like Egypt and Mesopotamia. Even today, perfumes help us feel good and leave a strong impression. The boxes that hold these perfumes are super important. They keep the bottles safe and tell the story of the brand. At GoBespokePackaging, we make custom boxes that show off your brand and make your perfumes even more special. Let us help you with packaging that makes your perfumes stand out and be remembered.",
    src: [
      "../assets/img/box-styles/perfume-boxes/perfume-boxes-1.jpg",
      "../assets/img/box-styles/perfume-boxes/perfume-boxes-2.jpg",
      "../assets/img/box-styles/perfume-boxes/perfume-boxes-3.jpg",
      "../assets/img/box-styles/perfume-boxes/perfume-boxes-4.jpg",
      "../assets/img/box-styles/perfume-boxes/perfume-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 4,
    name: "Pillow Boxes",
    desc: "Pillow boxes are cute and handy for many purposes e.g. gifts and small items. These boxes have been around for quite a while, known for their charming pillow shape. At GoBespokePackaging, we create custom pillow boxes that not only look great but keep your items safe. You can personalize them with your brand's name and special designs to make a lasting impression. Explore our cost-effective options and free shipping to find the perfect packaging for your needs.",
    src: [
      "../assets/img/box-styles/pillow-boxes/pillow-boxes-1.jpg",
      "../assets/img/box-styles/pillow-boxes/pillow-boxes-2.jpg",
      "../assets/img/box-styles/pillow-boxes/pillow-boxes-3.jpg",
      "../assets/img/box-styles/pillow-boxes/pillow-boxes-4.jpg",
      "../assets/img/box-styles/pillow-boxes/pillow-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 5,
    name: "Four Corner Boxes",
    desc: "Our Custom Four Corner boxes are the perfect choice for a wide range of products, from small electrical appliances to bakery items. These boxes offer unique customization options and are made from durable materials, ensuring your products receive optimal protection. You can personalize these boxes with your brand logo, product names in variety of colors and surface finishes, making the unboxing experience special for your customers. If you need clear windows, silk finishes or inserts, just let us know and we'll make it happen. Take advantage of our incredible discounts on bulk orders, along with free shipping for unbeatable value.",
    src: [
      "../assets/img/box-styles/four-corner-boxes/four-corner-boxes-1.jpg",
      "../assets/img/box-styles/four-corner-boxes/four-corner-boxes-2.jpg",
      "../assets/img/box-styles/four-corner-boxes/four-corner-boxes-3.jpg",
      "../assets/img/box-styles/four-corner-boxes/four-corner-boxes-4.jpg",
      "../assets/img/box-styles/four-corner-boxes/four-corner-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 6,
    name: "Tea Boxes",
    desc: "Tea boxes offer a stylish and convenient way to store and display your beloved tea blends. An interesting tidbit: Turkey holds the title as the world's largest consumer of tea, while China remains the top producer. Our tea boxes can be customized with a see-through window and unlimited add-on options. Whether you’re looking to sell black, green or herbal tea, our customizable tea boxes have you covered. Keep your tea fresh and present it delightfully with our user-friendly and visually appealing tea boxes.",
    src: [
      "../assets/img/box-styles/tea-boxes/tea-boxes-1.jpg",
      "../assets/img/box-styles/tea-boxes/tea-boxes-2.jpg",
      "../assets/img/box-styles/tea-boxes/tea-boxes-3.jpg",
      "../assets/img/box-styles/tea-boxes/tea-boxes-4.jpg",
      "../assets/img/box-styles/tea-boxes/tea-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 7,
    name: "Noodle Boxes",
    desc: "Noodle boxes provide a delightful way to enjoy your favorite noodle dishes. Available in various sizes with metal handles, they're suitable for takeout meals. Fun fact: noodles have a rich history dating back over 4,000 years to ancient China. At Go Bespoke Packaging, we offer customizable noodle boxes that can be tailored to your specific needs. Whether you're serving classic ramen or stir-fry noodles, our boxes can be personalized to make your brand truly unique from the competitors. Keep your noodles fresh and hassle-free with our easy-to-use and eco-friendly noodle boxes.",
    src: [
      "../assets/img/box-styles/noodle-boxes/noodle-boxes-1.jpg",
      "../assets/img/box-styles/noodle-boxes/noodle-boxes-2.jpg",
      "../assets/img/box-styles/noodle-boxes/noodle-boxes-3.jpg",
      "../assets/img/box-styles/noodle-boxes/noodle-boxes-4.jpg",
      "../assets/img/box-styles/noodle-boxes/noodle-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 8,
    name: "Playing Card Boxes",
    desc: "Playing card boxes are a great choice for packing your favorite card decks. They can be customized in any size, style and design. Did you know that playing cards have a history dating back over a thousand years, originating in China and spreading worldwide? At GBP, we offer customizable playing card boxes that can be tailored to your specific needs. Whether you're a poker player, a magician or a business owner, our boxes can be personalized to suit your requirements. You can even add hot stamping/foiling, embossing or SpotUV on these boxes for a premium look, along with custom printing.",
    src: [
      "../assets/img/box-styles/playing-card-boxes/playing-card-boxes-1.jpg",
      "../assets/img/box-styles/playing-card-boxes/playing-card-boxes-2.jpg",
      "../assets/img/box-styles/playing-card-boxes/playing-card-boxes-3.jpg",
      "../assets/img/box-styles/playing-card-boxes/playing-card-boxes-4.jpg",
      "../assets/img/box-styles/playing-card-boxes/playing-card-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 9,
    name: "Brochures",
    desc: "Brochures are like your marketing sidekicks. They're small, handy booklets that pack a punch. With a mix of pretty pictures and simple words, brochures help you talk to people who might become your customers. At GBP, we're experts at making custom brochures that fit your needs. We want to help you tell your story and stand out in a busy market. Whether you're inviting folks to an event, showing off your stuff or sharing important info, our brochures make sure your message gets through and stays in people's minds.",
    src: [
      "../assets/img/box-styles/brochures/brochures-1.jpg",
      "../assets/img/box-styles/brochures/brochures-2.jpg",
      "../assets/img/box-styles/brochures/brochures-3.jpg",
      "../assets/img/box-styles/brochures/brochures-4.jpg",
      "../assets/img/box-styles/brochures/brochures-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 10,
    name: "Gold Foil Boxes",
    desc: "Gold foil is an incredibly thin layer, usually around 0.076 to 0.127 micrometers thick, carefully added to our custom cardboard boxes using a precise method called hot stamping. Gold has always represented luxury, making our boxes look exceptionally premium. That's why many top-tier brands use gold foil to signify the value of what's inside. We have advanced machines operated by skilled experts to handle this delicate process. This ensures that each box is a masterpiece, bound to capture attention and create a lasting memory. Opt for our custom gold foil packaging to make your products truly stand out and tell a unique story that resonates with your customers.",
    src: [
      "../assets/img/box-styles/gold-foil-boxes/gold-foil-boxes-1.jpg",
      "../assets/img/box-styles/gold-foil-boxes/gold-foil-boxes-2.jpg",
      "../assets/img/box-styles/gold-foil-boxes/gold-foil-boxes-3.jpg",
      "../assets/img/box-styles/gold-foil-boxes/gold-foil-boxes-4.jpg",
      "../assets/img/box-styles/gold-foil-boxes/gold-foil-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 11,
    name: "Product Boxes",
    desc: "Product boxes are the all-in-one solution for your packaging needs. This is the most widely used box style in the packaging world which reflects its importance. These boxes are like a trusted friend, ready to protect and showcase a wide range of items, from electronics to personal care items. They come in different bottom styles - Reverse Tuck End, Straight Tuck End, Seal End, 123 Bottom and Auto Lock. Choose the perfect product box to suit your needs and let your packaging make a lasting impression on your customers.",
    src: [
      "../assets/img/box-styles/product-boxes/product-boxes-1.jpg",
      "../assets/img/box-styles/product-boxes/product-boxes-2.jpg",
      "../assets/img/box-styles/product-boxes/product-boxes-3.jpg",
      "../assets/img/box-styles/product-boxes/product-boxes-4.jpg",
      "../assets/img/box-styles/product-boxes/product-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 12,
    name: "Chinese Food Boxes",
    desc: "Chinese food boxes have a special role in preserving the goodness of your favorite dishes and sharing a piece of Chinese culinary history. Did you know that takeout boxes resembling the iconic folded container were first patented in the United States in 1894? They've since become a symbol of convenience and delicious flavors. At Go Bespoke Packaging, we offer custom packaging solutions that not only keep your food fresh but also convey your brand's identity, creating a memorable experience for your customers. Choose from various sizes and styles to perfectly complement your tasty offerings.",
    src: [
      "../assets/img/box-styles/chinese-food-boxes/chinese-food-boxes-1.jpg",
      "../assets/img/box-styles/chinese-food-boxes/chinese-food-boxes-2.jpg",
      "../assets/img/box-styles/chinese-food-boxes/chinese-food-boxes-3.jpg",
      "../assets/img/box-styles/chinese-food-boxes/chinese-food-boxes-4.jpg",
      "../assets/img/box-styles/chinese-food-boxes/chinese-food-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 13,
    name: "Cigarette Boxes",
    desc: "Cigarette boxes used to store and protect cigarettes. They have been a part of smoking culture for many years, first iconic flip-top cigarette box introduced in the early 20th century. At GoBespokePackaging, we specialize in producing custom cigarette boxes with your brand name, logo, artwork and wide range of add-ons to create a lasting impression on your customers. Discover a range of options for 20-packs, 24-packs, 100s or custom pre-rolls, including various sizes and styles, to help you find the ideal packaging solution for your tobacco products.",
    src: [
      "../assets/img/box-styles/cigarette-boxes/cigarette-boxes-1.jpg",
      "../assets/img/box-styles/cigarette-boxes/cigarette-boxes-2.jpg",
      "../assets/img/box-styles/cigarette-boxes/cigarette-boxes-3.jpg",
      "../assets/img/box-styles/cigarette-boxes/cigarette-boxes-4.jpg",
      "../assets/img/box-styles/cigarette-boxes/cigarette-boxes-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 14,
    name: "Earring Holder",
    desc: "Cardboard earring holders are a practical and eco-friendly choice for organizing and displaying your earrings. They are customizable to your brand's style and come in various shapes and sizes. Interestingly, earrings have been worn for thousands of years, with origins dating back to ancient civilizations. At GBP, we specialize in crafting personalized cardboard earring holders that not only keep your earrings safe and well-organized but also promote your brand's identity. Choose from a range of designs and finishes to enhance the presentation of your jewelry, leaving a lasting impression on your customers.",
    src: [
      "../assets/img/box-styles/earring-holder/earring-holder-1.jpg",
      "../assets/img/box-styles/earring-holder/earring-holder-2.jpg",
      "../assets/img/box-styles/earring-holder/earring-holder-3.jpg",
      "../assets/img/box-styles/earring-holder/earring-holder-4.jpg",
      "../assets/img/box-styles/earring-holder/earring-holder-5.jpg",
    ],
    url: "",
    clsx: "",
  },
  {
    id: 15,
    name: "Hemp Packaging",
    desc: "Custom hemp boxes are the perfect packaging solution for your hemp-based products, including hemp oil, cookies, drinks, cosmetics and other CBD items. Hemp, with its rich history dating back thousands of years, is a symbol of strength and eco-friendliness. We offer customized hemp packaging that aligns with your brand's commitment to sustainability and the uniqueness of your hemp-based products. Whether you're in the CBD, food or cosmetics industry, our hemp boxes provide an eco-conscious and eye-catching packaging solution. With various sizes and design possibilities, your hemp products can stand out on the shelves while making a positive impact on the environment. Choose our custom hemp boxes for responsible and impactful packaging.",
    src: [
      "../assets/img/box-styles/hemp-boxes/hemp-boxes-1.jpg",
      "../assets/img/box-styles/hemp-boxes/hemp-boxes-2.jpg",
      "../assets/img/box-styles/hemp-boxes/hemp-boxes-3.jpg",
      "../assets/img/box-styles/hemp-boxes/hemp-boxes-4.jpg",
      "../assets/img/box-styles/hemp-boxes/hemp-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 16,
    name: "Sleeve Boxes",
    desc: "Sleeve boxes, also known as drawer boxes, have gained popularity thanks to their distinctive design, where the outer sleeve smoothly slides over the inner tray. These boxes offer a combination of visual appeal and practical functionality. They are suitable for a wide range of products, including sweets, chocolates, vapes, reed diffusers, scented taper candles and countless others. With us, you have the flexibility to select premium rigid, regular white cardboard or eco-friendly brown kraft stock for your boxes, catering to your specific needs. There's no limit of customization with us, ensuring your packaging perfectly suits your brand and product.",
    src: [
      "../assets/img/box-styles/sleeve-boxes/sleeve-boxes-1.jpg",
      "../assets/img/box-styles/sleeve-boxes/sleeve-boxes-2.jpg",
      "../assets/img/box-styles/sleeve-boxes/sleeve-boxes-3.jpg",
      "../assets/img/box-styles/sleeve-boxes/sleeve-boxes-4.jpg",
      "../assets/img/box-styles/sleeve-boxes/sleeve-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 17,
    name: "Two Piece",
    desc: "Two-piece or telescopic boxes are a practical and versatile packaging solution. They are commonly used for a variety of items like games, chocolates, business cards and others. offering both protection and easy access. These boxes consist of a separate lid and base, making them easy to open and close. You can choose from various sizes, designs and finishes to create a packaging solution that perfectly represents your brand and keeps your product safe. Punch insert is most commonly ordered with this box style to keep the product in-place and safe.",
    src: [
      "../assets/img/box-styles/two-piece-boxes/two-piece-boxes-1.jpg",
      "../assets/img/box-styles/two-piece-boxes/two-piece-boxes-2.jpg",
      "../assets/img/box-styles/two-piece-boxes/two-piece-boxes-3.jpg",
      "../assets/img/box-styles/two-piece-boxes/two-piece-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 18,
    name: "Popcorn Boxes",
    desc: "Popcorn boxes are a classic symbol of movie nights and fun snacks. They've been around for a long time, with their origins tracing back to the late 1920s when popcorn became a popular cinema treat. We specialize in producing custom popcorn boxes that not only carry popcorn but also serve as powerful marketing tool. You can customize them with your own branding, artwork and size. Explore our options to find the perfect packaging for your delicious popcorn, ensuring your customers enjoy a tasty treat in style.",
    src: [
      "../assets/img/box-styles/popcorn-boxes/popcorn-boxes-1.jpg",
      "../assets/img/box-styles/popcorn-boxes/popcorn-boxes-2.jpg",
      "../assets/img/box-styles/popcorn-boxes/popcorn-boxes-3.jpg",
      "../assets/img/box-styles/popcorn-boxes/popcorn-boxes-4.jpg",
      "../assets/img/box-styles/popcorn-boxes/popcorn-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 19,
    name: "123 Bottom",
    desc: "Tuck end 123 bottom boxes are a practical packaging solution, named for their unique design where the bottom interlocks in three steps. Also known as snap or crush lock boxes. Though they may take a bit longer to assemble but they provide enhanced durability as compared to straight and reverse tuck end style. 1-2-3 Bottom boxes are a cost-effective choice for packaging your products securely. They come in different sizes to snuggly fit your products.",
    src: [
      "../assets/img/box-styles/123-bottom/123-bottom-1.jpg",
      "../assets/img/box-styles/123-bottom/123-bottom-2.jpg",
      "../assets/img/box-styles/123-bottom/123-bottom-3.jpg",
      "../assets/img/box-styles/123-bottom/123-bottom-4.jpg",
      "../assets/img/box-styles/123-bottom/123-bottom-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 20,
    name: "Hexagon",
    desc: "Our custom hexagon boxes are perfect for unique products, providing ample space and attractive printing quality. Ideal for cosmetics, candles, custom gifts and more. Create unforgettable memories by packing chocolates, sweets or other cherished items for your loved ones or customers. They come in hinged lid and two-piece configurations, where the lid completely detaches from the base. With various sizes, colors, and artwork to choose from, these boxes help your items stand out effectively.",
    src: [
      "../assets/img/box-styles/hexagon-boxes/hexagon-boxes-1.jpg",
      "../assets/img/box-styles/hexagon-boxes/hexagon-boxes-2.jpg",
      "../assets/img/box-styles/hexagon-boxes/hexagon-boxes-3.jpg",
      "../assets/img/box-styles/hexagon-boxes/hexagon-boxes-4.jpg",
      "../assets/img/box-styles/hexagon-boxes/hexagon-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 21,
    name: "Favor Boxes",
    desc: "Humans are social beings who cherish giving and receiving attractive gifts on various occasions. In weddings, birthdays or office parties, these custom boxes play a pivotal role in presenting adorable gifts to attendees. Favor boxes are special for showing appreciation and celebrate with loved ones. You can customize them with your own size, material and designs to make your celebrations even more memorable. The importance of tailored packaging becomes evident when impressing high-ranking officials and elite society members. Make every occasion memorable with our custom boxes.",
    src: [
      "../assets/img/box-styles/favor-boxes/favor-boxes-1.jpg",
      "../assets/img/box-styles/favor-boxes/favor-boxes-2.jpg",
      "../assets/img/box-styles/favor-boxes/favor-boxes-3.jpg",
      "../assets/img/box-styles/favor-boxes/favor-boxes-4.jpg",
      "../assets/img/box-styles/favor-boxes/favor-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 22,
    name: "Handle Boxes",
    desc: "Handle boxes are incredibly useful for carrying things. They've been around for a long time. Did you know that the first patent for a shopping bag with handles was granted in the early 1900s? At Go Bespoke Packaging, we make custom handle boxes that are not just handy but show off your brand. Our custom handle boxes serve all purposes, from gift packaging to food items like cakes and candies. You can print your brand name, logo and unique graphics over these boxes, making your products more memorable. Check out our budget-friendly options and free shipping to find the perfect packaging for your needs.",
    src: [
      "../assets/img/box-styles/handle-boxes/handle-boxes-1.jpg",
      "../assets/img/box-styles/handle-boxes/handle-boxes-2.jpg",
      "../assets/img/box-styles/handle-boxes/handle-boxes-3.jpg",
      "../assets/img/box-styles/handle-boxes/handle-boxes-4.jpg",
      "../assets/img/box-styles/handle-boxes/handle-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 23,
    name: "Wine Boxes",
    desc: "Wine boxes are more than just packaging; they are the guardians of a fine tradition. These boxes hold not just bottles but the essence of celebrations, the warmth of gatherings, the joy of toasting life's moments and we reckon this. Crafted to perfection, our custom wine boxes not only protect your bottles but also enhance the entire gifting experience. With a rich history dating back centuries, wine has been a symbol of refined taste and celebration. Our wine boxes are designed to complement this legacy. Whether you're a winery or an individual aiming to add a personal touch to your gifts, we have the perfect solution for you. Celebrate life's moments with our custom wine boxes, ensuring your gift is as remarkable as the occasion itself.",
    src: [
      "../assets/img/box-styles/wine-boxes/wine-boxes-1.jpg",
      "../assets/img/box-styles/wine-boxes/wine-boxes-2.jpg",
      "../assets/img/box-styles/wine-boxes/wine-boxes-3.jpg",
      "../assets/img/box-styles/wine-boxes/wine-boxes-4.jpg",
      "../assets/img/box-styles/wine-boxes/wine-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 24,
    name: "Dispenser Boxes",
    desc: "Dispenser boxes are a valuable addition to product packaging, designed for easy access and practicality. Did you know that the concept of dispenser dates back to ancient times when they were used for spices and herbs? Today, these boxes are essential for various small items, such as candies, lip balm and more. At GoBespokePackaging, we provide custom dispenser boxes that ensure your products are not only well-presented but also easily accessible for customers. Elevate your branding and packaging with our durable, user-friendly dispenser boxes, available at affordable rates to help your business grow.",
    src: [
      "../assets/img/box-styles/dispenser-boxes/dispenser-boxes-1.jpg",
      "../assets/img/box-styles/dispenser-boxes/dispenser-boxes-2.jpg",
      "../assets/img/box-styles/dispenser-boxes/dispenser-boxes-3.jpg",
      "../assets/img/box-styles/dispenser-boxes/dispenser-boxes-4.jpg",
      "../assets/img/box-styles/dispenser-boxes/dispenser-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 25,
    name: "Cereal Boxes",
    desc: "Cereal boxes, a morning ritual for many, play a key role in starting the day right. They keep our favorite cereals fresh and provide information about the flavors inside. Custom cereal boxes are a fantastic way to make your brand known at breakfast tables. At GBP, we create custom packaging that not only preserves the crunch of your cereals but also showcase your brand's personality, ensuring your product gets noticed from store shelves to breakfast menu. Make your cereal the highlight of every morning with our custom packaging. Available in various shapes and sizes.",
    src: [
      "../assets/img/box-styles/cereal-boxes/cereal-boxes-1.jpg",
      "../assets/img/box-styles/cereal-boxes/cereal-boxes-2.jpg",
      "../assets/img/box-styles/cereal-boxes/cereal-boxes-3.jpg",
      "../assets/img/box-styles/cereal-boxes/cereal-boxes-4.jpg",
      "../assets/img/box-styles/cereal-boxes/cereal-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 26,
    name: "French Fry Boxes",
    desc: "French fries, a yummy snack, have a special place in our hearts and appetites. They're a tasty treat that's been enjoyed for generations and their popularity keeps on growing. Custom French fry boxes not only keep these tasty treats warm and crispy but also offer a great way to promote your brand. They're like a little piece of your business in every bite. At GoBespokePackaging, we create custom packaging that's perfect for your French fries, ensuring they stay fresh and your brand stays in customers' minds. Make your fries the star of the show with our customized boxes!",
    src: [
      "../assets/img/box-styles/french-fry-boxes/french-fry-boxes-1.jpg",
      "../assets/img/box-styles/french-fry-boxes/french-fry-boxes-2.jpg",
      "../assets/img/box-styles/french-fry-boxes/french-fry-boxes-3.jpg",
      "../assets/img/box-styles/french-fry-boxes/french-fry-boxes-4.jpg",
      "../assets/img/box-styles/french-fry-boxes/french-fry-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 27,
    name: "Gift Boxes",
    desc: "Gift boxes are more than just a nice cover with a bow. They can turn a regular item into a one-of-a-kind, cherished gift. In the USA, custom printed gift boxes are getting more popular as businesses aim to be unique in a busy market. So, boost your business with our custom gift boxes and create unforgettable gifting moments! These boxes come in all sorts of shapes and sizes, designed and printed to match your ideas perfectly. They add a personal touch to your gift-giving, making each present truly special.",
    src: [
      "../assets/img/box-styles/gift-boxes/gift-boxes-1.jpg",
      "../assets/img/box-styles/gift-boxes/gift-boxes-2.jpg",
      "../assets/img/box-styles/gift-boxes/gift-boxes-3.jpg",
      "../assets/img/box-styles/gift-boxes/gift-boxes-4.jpg",
      "../assets/img/box-styles/gift-boxes/gift-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 28,
    name: "Bakery Boxes",
    desc: "Bakery boxes are an essential part of any bakery business. These boxes not only keep your delicious treats fresh but also serve as a canvas for your brand. They come in various sizes and styles, making them suitable for a range of baked goods, from cupcakes to pastries. We specialize in creating custom bakery boxes that add a personal touch to your sweet creations. With your branding, design and size preferences, these boxes are a perfect fit for your bakery's unique needs. At GoBespokePackaging, we help you leave a sweet impression on your customers.",
    src: [
      "../assets/img/box-styles/bakery-boxes/bakery-boxes-1.jpg",
      "../assets/img/box-styles/bakery-boxes/bakery-boxes-2.jpg",
      "../assets/img/box-styles/bakery-boxes/bakery-boxes-3.jpg",
      "../assets/img/box-styles/bakery-boxes/bakery-boxes-4.jpg",
      "../assets/img/box-styles/bakery-boxes/bakery-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 29,
    name: "Soap Boxes",
    desc: "Soap is something we all need every day, and custom soap boxes help it shine on store shelves. They not only protect the soap but also showcase the brand effectively. Unique designs and artwork on them can make your business truly eye-catching. By using custom printing with your brand logo and wisely blending it with add-ons like foil and Spot UV, you’ll not only capture the customer’s attention browsing down the aisles but urge them to pick it and use the product inside. Our premium printing with an experienced team will help grow your business.",
    src: [
      "../assets/img/box-styles/soap-boxes/soap-boxes-1.jpg",
      "../assets/img/box-styles/soap-boxes/soap-boxes-2.jpg",
      "../assets/img/box-styles/soap-boxes/soap-boxes-3.jpg",
      "../assets/img/box-styles/soap-boxes/soap-boxes-4.jpg",
      "../assets/img/box-styles/soap-boxes/soap-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 30,
    name: "Eco-friendly Kraft Boxes",
    desc: "Eco-friendly packaging can save on carbon footprint by reducing resource-intensive production, transportation and waste, contributing to overall environmental conservation. As sustainability gains importance, eco-friendly Kraft boxes have become essential. Our Kraft boxes are designed to mirror your dedication to preserving nature while delivering top-quality products. Whether you're a brand looking to make a green statement or a conscious consumer, our customizable eco-friendly Kraft boxes are the perfect solution for you.",
    src: [
      "../assets/img/box-styles/eco-friendly-kraft-boxes/eco-friendly-kraft-boxes-1.jpg",
      "../assets/img/box-styles/eco-friendly-kraft-boxes/eco-friendly-kraft-boxes-2.jpg",
      "../assets/img/box-styles/eco-friendly-kraft-boxes/eco-friendly-kraft-boxes-3.jpg",
      "../assets/img/box-styles/eco-friendly-kraft-boxes/eco-friendly-kraft-boxes-4.jpg",
      "../assets/img/box-styles/eco-friendly-kraft-boxes/eco-friendly-kraft-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 31,
    name: "Burger Boxes",
    desc: "Custom cardboard burger boxes are the ideal choice for your mouthwatering burgers. They not only protect your tasty treats but also add a personal touch to your brand. We're experts in creating custom cardboard burger boxes that are eco-friendly and look great. Burgers are all about convenience and flavor, and our cardboard boxes are designed to keep them hot and tasty. Whether you run a burger place or just love burgers on the go, our customizable cardboard burger boxes are the solution. Enjoy your burgers to the fullest with our custom cardboard burger boxes, keeping them delicious from the first bite to the last.",
    src: [
      "../assets/img/box-styles/burger-boxes/burger-boxes-1.jpg",
      "../assets/img/box-styles/burger-boxes/burger-boxes-2.jpg",
      "../assets/img/box-styles/burger-boxes/burger-boxes-3.jpg",
      "../assets/img/box-styles/burger-boxes/burger-boxes-4.jpg",
      "../assets/img/box-styles/burger-boxes/burger-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 32,
    name: "Macaron Boxes",
    desc: "Macarons have a rich history, known for their elegance and taste. Macaron boxes are all about keeping these delicious and colorful treats safe and looking good. Our macaron boxes are designed to ensure these sweet treats arrive in perfect condition. Whether you're a bakery owner or a macaron enthusiast, our customizable macaron boxes are here to help you present and protect these delectable goodies. You can enhance your customer's experience by customizing these boxes with features like a window, hot stamping and Spot UV to make them stand out.",
    src: [
      "../assets/img/box-styles/macaron-boxes/macaron-boxes-1.jpg",
      "../assets/img/box-styles/macaron-boxes/macaron-boxes-2.jpg",
      "../assets/img/box-styles/macaron-boxes/macaron-boxes-3.jpg",
      "../assets/img/box-styles/macaron-boxes/macaron-boxes-4.jpg",
      "../assets/img/box-styles/macaron-boxes/macaron-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 33,
    name: "Bottle Carrier",
    desc: "Designed to securely hold beverages, including bottles, beer cans and soft drinks, ensuring your items remain snug during transport. An ideal solution for both carrying beverages and promoting your brand, these carriers are perfect for those in the beverage industry or planning special events. With a rich history of reliability and sustainability, these carriers ensure your drinks arrive at their destination intact and in style. Customize your eco-friendly Bottle Carriers with us, available for 2, 4, and 6 beverages, to represent your brand while keeping your drinks safe. Additionally, these carriers are delivered flat to resolve storage issues, making them a convenient choice.",
    src: [
      "../assets/img/box-styles/bottle-carrier/bottle-carrier-1.jpg",
      "../assets/img/box-styles/bottle-carrier/bottle-carrier-2.jpg",
      "../assets/img/box-styles/bottle-carrier/bottle-carrier-3.jpg",
      "../assets/img/box-styles/bottle-carrier/bottle-carrier-4.jpg",
      "../assets/img/box-styles/bottle-carrier/bottle-carrier-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 34,
    name: "Custom Metalized Boxes",
    desc: "These metalized boxes are designed to add a touch of luxury to your packaging range. Their shimmering and reflective surfaces make your products stand out on the shelves, ensuring they catch the customer's eye. These boxes not only offer visual appeal but also provide excellent protection. Whether you're in the cosmetics, electronics or retail business, our custom metalized boxes are the perfect choice for leaving a lasting impression. You can further strengthen your branding with custom printing, embossing or debossing. Let your products shine in our custom metalized boxes.",
    src: [
      "../assets/img/box-styles/custom-metalized-boxes/custom-metalized-boxes-1.jpg",
      "../assets/img/box-styles/custom-metalized-boxes/custom-metalized-boxes-2.jpg",
      "../assets/img/box-styles/custom-metalized-boxes/custom-metalized-boxes-3.jpg",
      "../assets/img/box-styles/custom-metalized-boxes/custom-metalized-boxes-4.jpg",
      "../assets/img/box-styles/custom-metalized-boxes/custom-metalized-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 35,
    name: "Custom Bookend Boxes",
    desc: "These versatile boxes are designed not just to hold your products but to stand out and enhance the unboxing experience, providing a premium touch to your brand. Ideal for packaging a wide range of items, from books to promotional materials, our custom bookend boxes offer a practical and stylish solution. They not only keep your products organized but also enhance the overall aesthetic. Whether you're a publisher, a business owner or an event organizer, our custom bookend boxes are the perfect choice for showcasing your items. You can further personalize them with your brand logo, custom design and premium rigid stock to create a memorable unboxing experience for your customers.",
    src: [
      "../assets/img/box-styles/custom-bookend-boxes/custom-bookend-boxes-1.jpg",
      "../assets/img/box-styles/custom-bookend-boxes/custom-bookend-boxes-2.jpg",
      "../assets/img/box-styles/custom-bookend-boxes/custom-bookend-boxes-3.jpg",
      "../assets/img/box-styles/custom-bookend-boxes/custom-bookend-boxes-4.jpg",
      "../assets/img/box-styles/custom-bookend-boxes/custom-bookend-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 36,
    name: "Pie Boxes",
    desc: "Pies are delightful and our custom pie boxes make them look even more delicious. They're perfect for bakeries and food businesses. These boxes not only keep your pies fresh but also make your brand stand out. You can customize them with your brand logo, custom design, Gold Foil, Spot UV, Embossing and even add a see-through window so your customers can see the beautiful and delicious pies inside. Whether you're selling slices or whole pies, your pies will shine in our custom printed pie boxes.",
    src: [
      "../assets/img/box-styles/pie-boxes/pie-boxes-1.jpg",
      "../assets/img/box-styles/pie-boxes/pie-boxes-2.jpg",
      "../assets/img/box-styles/pie-boxes/pie-boxes-3.jpg",
      "../assets/img/box-styles/pie-boxes/pie-boxes-4.jpg",
      "../assets/img/box-styles/pie-boxes/pie-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 37,
    name: "Donut Boxes",
    desc: "Donuts are a beloved treat that deserves packaging that not only keeps them fresh but also makes them look even more tempting. Our custom donut boxes are the ideal choice for bakeries and food businesses, offering practicality and visual appeal. These boxes not only protect your donuts but also enhance your brand's presentation. Whether you're selling individual donuts or dozens, our custom donut boxes can be tailored to your specific needs. You can even add your logo and unique designs to make your donuts even more irresistible. Make your donuts impossible to resist with our custom printed donut boxes.",
    src: [
      "../assets/img/box-styles/donut-boxes/donut-boxes-1.jpg",
      "../assets/img/box-styles/donut-boxes/donut-boxes-2.jpg",
      "../assets/img/box-styles/donut-boxes/donut-boxes-3.jpg",
      "../assets/img/box-styles/donut-boxes/donut-boxes-4.jpg",
      "../assets/img/box-styles/donut-boxes/donut-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 38,
    name: "Muffin Boxes",
    desc: "Muffins, those delightful baked goodies, deserve packaging that keeps them fresh and showcases their homemade charm. Our custom printed muffin boxes are tailor-made to protect and present these treats with a personal touch. Whether you're a bakery owner or a food business, these boxes offer a practical and appealing packaging solution. They don't just preserve your muffins; they also enhance your brand's unique character. From individual muffins to a batch, our custom muffin boxes can be customized to your specific needs. You have the freedom to add your brand logo and special designs, making your muffins even more appealing.",
    src: [
      "../assets/img/box-styles/muffin-boxes/muffin-boxes-1.jpg",
      "../assets/img/box-styles/muffin-boxes/muffin-boxes-2.jpg",
      "../assets/img/box-styles/muffin-boxes/muffin-boxes-3.jpg",
      "../assets/img/box-styles/muffin-boxes/muffin-boxes-4.jpg",
      "../assets/img/box-styles/muffin-boxes/muffin-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 39,
    name: "Retail Boxes",
    desc: "When it comes to selling products, the right packaging can make all the difference. Our custom retail boxes are designed to not only protect your items but also to catch the eye of potential buyers. Whether you run a store or an online shop, these boxes offer a practical and attractive packaging solution. You can customize these boxes with your own artwork, size, sustainable material and wide range of add-on options from gold foil to debossing. With all these customization options, your retail boxes will stand out in a crowded market. Make your products shine and leave a lasting impression with our custom printed retail boxes.",
    src: [
      "../assets/img/box-styles/retail-boxes/retail-boxes-1.jpg",
      "../assets/img/box-styles/retail-boxes/retail-boxes-2.jpg",
      "../assets/img/box-styles/retail-boxes/retail-boxes-3.jpg",
      "../assets/img/box-styles/retail-boxes/retail-boxes-4.jpg",
      "../assets/img/box-styles/retail-boxes/retail-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
  {
    id: 40,
    name: "Corrugated Boxes",
    desc: "These sturdy, dependable boxes are the unsung heroes of packaging. Whether you're moving, shipping products or need storage solutions, corrugated boxes are the way to go. They're practical, durable and cost-effective. With customization options like printing your logo or special designs, both side print and add-ons - our corrugated boxes do more than just protecting your items; they also represent your brand with strength and reliability. Choose corrugated boxes for your packaging needs and experience the perfect blend of practicality and brand representation. These boxes can be made in any style and size you want.",
    src: [
      "../assets/img/box-styles/corrugated-boxes/corrugated-boxes-1.jpg",
      "../assets/img/box-styles/corrugated-boxes/corrugated-boxes-2.jpg",
      "../assets/img/box-styles/corrugated-boxes/corrugated-boxes-3.jpg",
      "../assets/img/box-styles/corrugated-boxes/corrugated-boxes-4.jpg",
      "../assets/img/box-styles/corrugated-boxes/corrugated-boxes-5.jpg",
    ],
    url: "",
    clsx: "offset-lg-1",
  },
];
