import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
// const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const PrivacyPolicyInner = React.lazy(() => import("../components/PrivacyPolicyInner"));
// const FooterBottomOne = React.lazy(() =>
//   import("../components/FooterBottomOne")
// );
// const FooterOne = React.lazy(() => import("../components/FooterOne"));
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));
const PrivacyPolicy = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* SupportBarOne */}
          <SupportBarOne />

          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarTwo />

          {/* Breadcrumb */}
          {/* <Breadcrumb title={"CONTACT US"} /> */}

          {/* Breadcrumb */}
          <PrivacyPolicyInner />

          {/* Footer Two */}
          <FooterTwo />

          {/* Footer Bottom One */}
          {/* <FooterBottomOne /> */}
        </Suspense>
      </Fragment>
    </>
  );
};

export default PrivacyPolicy;
