import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const BoxStyles = React.lazy(() => import("../components/BoxStyles"));
const ContactInner = React.lazy(() => import("../components/ContactInner"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));
const Contact = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* SupportBarOne */}
          <SupportBarOne />

          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarTwo />

          {/* Breadcrumb */}
          {/* <Breadcrumb title={"CONTACT US"} /> */}

          {/* Box styles */}
          <BoxStyles />

          {/* Breadcrumb */}
          {/* <ContactInner /> */}

          {/* Footer One */}
          <FooterTwo />

          {/* Footer Bottom One */}
          {/* <FooterBottomOne /> */}
        </Suspense>
      </Fragment>
    </>
  );
};

export default Contact;
